import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { IconGridCards } from "../IconGridCards";

const WhatDistinguishesUsStyled = styled.div`
  #dist-us-section {
    color: #ffffff;
    background-color: #161e2f !important;

    .dist-us-title-container {
      margin-top: 0;
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #dist-us-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .dist-us-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #dist-us-paragraph {
        font-weight: 400;
      }
    }
    .logoCol {
      display: flex;
      justify-content: center;
      margin: 60px 0 0 0;
      img {
        width: 50%;
      }
    }
    .bullet-image {
      ul {
        li {
          color: #ffa500;
        }
        li {
          span {
            color: #ffffff;
            font-weight: 400;
          }
        }
      }

      img {
        width: 100%;
      }
    }
    #bullet-image-desktop {
      display: none;
    }
    #bullet-image-mobile {
      display: inherit;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #dist-us-section {
      .dist-us-title-container {
        margin-top: 8%;
      }
      #bullet-image-desktop {
        display: unset;
      }
      #bullet-image-mobile {
        display: none;
      }
      .bee-col {
        padding: 150px 30px;
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const WhatDistinguishesUs = () => {
  const bullets = [
    {
      icontype: "archive",
      numberId: "",
      h4: "100,000 +",
      h4Id: "",
      p: "Beehives for 2022",
    },
    {
      icontype: "users",
      numberId: "",
      h4: "100 +",
      h4Id: "",
      p: "Satisfied Growers",
    },
    {
      icontype: "plus-circle",
      numberId: "",
      h4: "400 +",
      h4Id: "",
      p: "Beekeepers",
    },
    {
      icontype: "bolt",
      numberId: "",
      h4: "58,458",
      h4Id: "",
      p: "Acres Pollinated",
    },
  ];

  return (
    <WhatDistinguishesUsStyled>
      <StandardSection idName="dist-us-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="dist-us-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="dist-us-title">The Values</h2>
                </Col>
              </Row>

              <h3 className="dist-us-subtitle" id="">
                What Distinguishes Us?
              </h3>
              <p id="dist-us-paragraph">
              We work with our beekeepers year round to ensure exceptional quality and strength. Using Infrared technology we're able to efficiently verify honey bee inventory.
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            <IconGridCards iconCards={bullets}></IconGridCards>
          </Col>
        </Row>
      </StandardSection>
    </WhatDistinguishesUsStyled>
  );
};
