import React from "react";
import styled from "styled-components";
import { Row, Col, Table } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { IconBulletPoints } from "../IconBulletPoints";
import { FieldForceBarChart } from "../FieldForceBarChart";

const DidYouKnowStyled = styled.div`
  #did-you-know-section {
    .did-you-know-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #did-you-know-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .did-you-know-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #did-you-know-paragraph {
        font-weight: 400;
      }
    }
    .field-force-col {
      margin-top: auto;
      margin-bottom: auto;
      .field-force-text {
        margin-bottom: 20px;
        font-weight: 400;
      }
    }

    .field-force-col,
    .chart-col,
    .bullets-col,
    .table-col {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .icon-numbers {
      font-size: 3px;
      margin: 6px 0;
    }
    .icon-text {
      font-size: 16px;
    }
    h4 {
      display: none;
    }
    #field-force {
      text-decoration: underline;
    }
    #desktop-bar-chart {
      display: none;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #did-you-know-section {
      .field-force-col {
        .field-force-text {
          margin-right: 36px;
        }
      }
      .field-force-col,
      .chart-col,
      .bullets-col,
      .table-col {
        padding: 15px 10px;
      }
      .chart-col {
        padding-top: 0;
      }
      .field-force-col {
        margin-top: 50px;
      }
      .table-col {
        padding: 15px 10px;
        table {
          width: 90%;
          float: right;
        }
      }
      .icon-text {
        p,
        span {
          font-size: 16px;
        }
      }
      #desktop-bar-chart {
        display: block !important;
      }
      #mobile-bar-chart {
        display: none;
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const DidYouKnow = () => {
  const bullets = [
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "(5.00) 4-Frame hives to equal the Field Force of (1.00) 8-frame hive",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "(2.50) 6-Frame hives to equal the Field Force of (1.00) 8-frame hive",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p:
        "(2.00) 8-frame hives to equal the Field Force of (1.65) 10-Frame hives ",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
  ];

  return (
    <DidYouKnowStyled>
      <StandardSection idName="did-you-know-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="did-you-know-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="did-you-know-title">Exchange Bees</h2>
                </Col>
              </Row>

              <h3 className="did-you-know-subtitle" id="">
                Did You Know?
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="field-force-col">
            <div className="field-force-text">
              An 8-frame colony contains approximately 16,000 honey bees but
              only 6,400 of those bees will be out foraging during the day,
              making up the Field Force which is the percentage of bees in every
              beehive that leave with the purpose of pollination.
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} className="chart-col">
            <FieldForceBarChart idName="" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="bullets-col">
            <h5>It would take:</h5>
            <IconBulletPoints iconCards={bullets}></IconBulletPoints>
          </Col>
          <Col xs={12} sm={12} md={6} className="table-col">
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Frames</th>
                  <th>Effective Field Force</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>4</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>12.5%</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>25%</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>40%</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>42.5%</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>45%</td>
                </tr>
                <tr>
                  <td>11</td>
                  <td>47.5%</td>
                </tr>
                <tr>
                  <td>12</td>
                  <td>50%</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </StandardSection>
    </DidYouKnowStyled>
  );
};
