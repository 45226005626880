import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { PollinationForm } from "../components/forms/PollinationForm";
import { StandardSection } from "../components/StandardSection";
import { IconBulletPoints } from "../components/IconBulletPoints";
import { SecondaryButton } from "../components/Buttons";
import { Row, Col } from "react-bootstrap";
import { Footer } from "../components/Footer";

import heroImage from "../assets/imgs/bee-house-bg.jpg";

const PollinationStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  #need-bees-section {
    .need-bees-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #need-bees-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .need-bees-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #need-bees-paragraph {
        font-weight: 400;
      }
    }
    .bullets-col {
      padding-top: 10px;
      padding-bottom: 10px;
      h4 {
        display: none;
      }
    }
    .icon-numbers {
      font-size: 3px;
      margin: 6px 0;
    }
    .icon-text {
      font-size: 16px;
    }
  }
  #logistics-section {
    background-color: #ffffff !important;
    .logistics-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #logistics-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .logistics-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #logistics-paragraph {
        font-weight: 400;
      }
    }
  }
  #contact-us-section {
    padding-bottom: 0;
    padding-top: 80px;
  }

  #contact-form-section {
    padding-top: 0;
    color: #ffffff;
    background-color: #161e2f !important;
    h2 {
      color: #ffffff;
      font-weight: 500;
    }
  }
`;

export const PollinationPage = () => {
  const history = useHistory();
  const bullets = [
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "We need an additional 32,000 hives for the 2021 pollination season.",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "Our growers orchards are consistently requiring more bees.",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "We offer wintering locations along with pollination contracts.",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
    {
      number: "",
      numberId: "",
      h4: "",
      h4Id: "",
      p: "Contracting safety to insure your payment for services.",
      xs1: "2",
      sm1: "2",
      md1: "2",
      xs2: "10",
      sm2: "10",
      md2: "10",
    },
  ];

  return (
    <PollinationStyled>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>Pollination Services - Exchange Bees</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center" title="Pollination"></Hero>
      <StandardSection idName="need-bees-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="need-bees-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="need-bees-title">Beekeepers</h2>
                </Col>
              </Row>

              <h3 className="need-bees-subtitle" id="">
                We Need Bees For Pollination
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="bullets-col">
            <IconBulletPoints iconCards={bullets}></IconBulletPoints>
          </Col>
        </Row>
      </StandardSection>
      <StandardSection idName="logistics-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="logistics-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="logistics-title">Logistics Help</h2>
                </Col>
              </Row>

              <h3 className="logistics-subtitle" id="">
                Connect With Our Partners
              </h3>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className="">
            <p id="contact-us-paragraph">
              We will connect you with reliable freight companies that provide
              bee transportation.
            </p>
            <p id="contact-us-paragraph">
              Orchard Placement Service - $3 per hive
            </p>
            <SecondaryButton href="#quote">
              Contact Us For More Info
            </SecondaryButton>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} className=""></Col>
        </Row>
      </StandardSection>
      <PollinationForm heading="" subheading=""></PollinationForm>
      <Footer />
    </PollinationStyled>
  );
};
