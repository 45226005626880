import React from "react";
import styled from "styled-components";
import { Form, Row, Col } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { IconBulletPoints } from "../IconBulletPoints";
import { SecondaryButton } from "../Buttons";
import { http } from "../../lib/http";

const PollinationFormStyled = styled.div`
  color: #000;
  font-weight: bold;
  #pollination-form-section {
    padding: 80px 0;
    .pollination-title-container {
      margin-bottom: 25px;
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #pollination-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .pollination-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #pollination-paragraph {
        font-weight: 400;
      }
    }
    .secondary-button {
      margin-top: 40px;
      align-items: center;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const PollinationForm = (props) => {
  // var date = new Date();

  const [form, setForm] = React.useState({});

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const response = await http.post("/api/v1/pollinationmessages", {
      ...form,
    });
    console.log("response", response);
    setForm({});
  };

  return (
    <PollinationFormStyled>
      <StandardSection
        idName="pollination-form-section"
        // heading={props.heading}
        // subheading={props.subheading}
      >
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="pollination-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="pollination-title">Questions?</h2>
                </Col>
              </Row>

              <h3 className="pollination-subtitle" id="">
                Pollination Service
              </h3>
              <p id="pollination-paragraph">
                We look forward to working with you! Please fill out our
                pollination service form and we will contact you!
              </p>
              <p id="pollination-paragraph"></p>
            </div>
          </Col>
          <Col>
            {/* <IconBulletPoints iconCards={bullets}></IconBulletPoints> */}
          </Col>
        </Row>
        <Form className="" id="quote" onSubmit={submit}>
          <Form.Row>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridEmail">
                <Form.Label>First and Last Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Full Name"
                  value={form.name || ""}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridCompany">
                <Form.Label>Company</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Exchange Bees"
                  value={form.company || ""}
                  onChange={(e) =>
                    setForm({ ...form, company: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="info@exchangebees.com"
                  value={form.email || ""}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                  required
                  placeholder="(916) 708-3488"
                  value={form.phone || ""}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridCityOperation">
                <Form.Label>City of Operation</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sacramento"
                  value={form.city || ""}
                  onChange={(e) => setForm({ ...form, city: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridHivesForAlmonds">
                <Form.Label>
                  How many hives will you have available for almonds?
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="100"
                  value={form.hives || ""}
                  onChange={(e) => setForm({ ...form, hives: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridWinteringCity">
                <Form.Label>
                  Where will your hives be wintering? (City)
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Sacramento"
                  value={form.wintering_city || ""}
                  onChange={(e) =>
                    setForm({ ...form, wintering_city: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridAdditionalInfo">
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="1"
                  placeholder="Additional information"
                  value={form.additional_info || ""}
                  onChange={(e) =>
                    setForm({ ...form, additional_info: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Form.Group controlId="formGridDate">
                <Form.Label>Calendar Date/Time for Appointment</Form.Label>
                <Form.Control
                  type="datetime-local"
                  placeholder="Date and Time"
                  value={form.preferred_datetime || ""}
                  onChange={(e) =>
                    setForm({ ...form, preferred_datetime: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <div className="text-right">
            <SecondaryButton type="submit">Submit</SecondaryButton>
          </div>
        </Form>
      </StandardSection>
    </PollinationFormStyled>
  );
};
