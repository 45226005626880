import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextCarouselStyled = styled.div`
  .text-carousel-wrapper {
    .review-row {
      margin: 20px 0;
      .review-title {
        font-size: 26px;
      }
      .left-arrow,
      .right-arrow {
        color: #ffffff;
        font-size: 36px;
      }
    }
    .image {
      height: 50px;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

class TextCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    const nextIndex =
      this.state.activeIndex === this.props.carouselItems.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    const nextIndex =
      this.state.activeIndex === 0
        ? this.props.carouselItems.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  nextInterval() {
    if (!this.timerId) {
      this.timerId = setInterval(() => {
        const nextIndex =
          this.state.activeIndex === this.props.carouselItems.length - 1
            ? 0
            : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
      }, 4000);
    }
  }

  render() {
    const { activeIndex } = this.state;
    this.nextInterval();
    return (
      <TextCarouselStyled>
        <div className="text-carousel-wrapper text-center">
          <Row className="review-row">
            <Col xs={2}>
              <FontAwesomeIcon
                icon={["fas", "chevron-left"]}
                className="left-arrow"
                alt="review carousel left"
                onClick={this.previous}
              />
            </Col>
            <Col xs={8}>
              <span className="review-title">
                {this.props.carouselItems[activeIndex].title}
              </span>
            </Col>
            <Col xs={2}>
              <FontAwesomeIcon
                icon={["fas", "chevron-right"]}
                className="right-arrow"
                alt="review carousel right"
                onClick={this.next}
              />
            </Col>
          </Row>
          {this.props.carouselItems[activeIndex].text}
          <Row className="image-row">
            <Col xs={12}>
              <div className="image-wrapper">
                {/* <a
                  href={this.props.carouselItems[activeIndex].href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="image"
                    src={this.props.carouselItems[activeIndex].image}
                    alt={this.props.carouselItems[activeIndex].imageAlt}
                  />
                </a> */}
              </div>
            </Col>
          </Row>
        </div>
      </TextCarouselStyled>
    );
  }
}

export default TextCarousel;
