import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconBulletPointsStyled = styled.div`
  .icon-rows:first-of-type {
    margin-top: 20px;
  }
  .icon-rows {
    .icon-numbers {
      font-size: 18px;
      font-weight: 700;
      color: #ffffff;
      background-color: #ffa500;
      border-radius: 50px;
      width: 2.5em;
      height: 2.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      float: right;
    }
    .icon-text {
      margin-left: -3px;
      margin-right: 10px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      p,
      span {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const IconBulletPoints = (props) => {
  return (
    <IconBulletPointsStyled>
      <Col xs={12} sm={12} md={12}>
        {props.iconCards.map(
          (
            {
              number,
              h4,
              p,
              span,
              numberId,
              h4Id,
              icontype,
              col1Classes,
              col2Classes,
              xs1,
              xs2,
              sm1,
              sm2,
              md1,
              md2,
            },
            i
          ) => (
            <Row className="icon-rows" key={i}>
              <Col xs={xs1} sm={sm1} md={md1}>
                <div className="icon-numbers" id={numberId}>
                  {number}
                  <FontAwesomeIcon id="icon" icon={icontype} />
                </div>
              </Col>
              <Col xs={xs2} sm={sm2} md={md2}>
                <div className="icon-text">
                  <h4 id={h4Id}>{h4}</h4>
                  <p>
                    {p}
                    {span}
                  </p>
                </div>
              </Col>
            </Row>
          )
        )}
      </Col>
    </IconBulletPointsStyled>
  );
};
