import React from "react";
import styled from "styled-components";

const FooterStyled = styled.footer`
  color: #000000;
  font-weight: 300;
  padding: 20px 0;
  .quicklinks li a {
    color: #ffa500;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const Footer = () => {
  return (
    <FooterStyled className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <span className="copyright">
              Copyright &copy; Exchange Bees 2020
            </span>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <ul className="list-inline quicklinks">
              <li className="list-inline-item">
                <a href="/privacy-policy">Privacy Policy</a>
              </li>
              <li className="list-inline-item">
                <a href="/terms">Terms of Use</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </FooterStyled>
  );
};
