import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { AboutUs } from "../components/sections/AboutUs";
import { ContactUs } from "../components/sections/ContactUs";
import { ContactForm } from "../components/forms/ContactForm";
import { Footer } from "../components/Footer";

import heroImage from "../assets/imgs/bee-house-bg.jpg";
import tanTexture from "../assets/imgs/tan-texture.jpg";

const AboutUsStyled = styled.div`
  .hero-enclosure {
    display: flex;
    align-items: center;
    /* height: 40vh; */
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    h1 {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
  #about-section {
    padding: 80px 0;
    text-align: justify;
  }
  #company-press-section {
    background-image: linear-gradient(
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 0.75),
        rgba(255, 255, 255, 0.75)
      ),
      url(${tanTexture});
    .section-heading {
      color: #ffa500;
    }
  }
  #contact-us-section {
    padding-bottom: 0;
    padding-top: 80px;
  }

  #contact-form-section {
    padding-top: 0;
    color: #ffffff;
    background-color: #161e2f !important;
    h2 {
      color: #ffffff;
      font-weight: 500;
    }
  }
`;

export const AboutUsPage = () => {
  const history = useHistory();
  return (
    <AboutUsStyled>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>About Exchange Bees – Almond Pollination Service</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <NavBar />
      <Hero jumboClass="hero-enclosure text-center" title="About us"></Hero>
      <AboutUs></AboutUs>
      <ContactUs />
      <ContactForm heading="" subheading=""></ContactForm>
      <Footer />
    </AboutUsStyled>
  );
};
