import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-bootstrap";
import { StandardSection } from "../components/StandardSection";
import { NavBar } from "../components/NavBar";
import { Hero } from "../components/Hero";
import { Footer } from "../components/Footer";
import TextCarousel from "../components/TextCarousel";
import { DidYouKnow } from "../components/sections/DidYouKnow";
import { CompanyOffers } from "../components/sections/CompanyOffers";
import { WhatDistinguishesUs } from "../components/sections/WhatDistinguishesUs";
import { BeehiveInventory } from "../components/sections/BeehiveInventory";
import { ContactUs } from "../components/sections/ContactUs";
import { ContactForm } from "../components/forms/ContactForm";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import heroImage from "../assets/imgs/home-hero.jpg";

const HomePageStyled = styled.div`
  .hero-enclosure {
    height: 80vh;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.35),
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.55)
      ),
      url(${heroImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .hero-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #hero-title {
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .hero-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
        font-size: 45px;
      }
      #hero-paragraph {
        font-size: 16px;
        font-weight: 400;
      }
    }
    .primary-button {
      margin-top: 2.5em;
      margin-bottom: 20%;
    }
    .ptag-enclosure {
      margin-top: 20px;
      margin-bottom: 20px;
      p {
        margin-bottom: 0px;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  #what-we-do-section {
    padding: 80px 0;
    background: #f8f9fa;
    .title-below {
      display: none;
    }
    h2 {
      font-weight: 600;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 45px;
    }
    h4 {
      margin-bottom: 25px;
    }
    h5 {
      margin-bottom: 20px;
    }
    .card {
      border: none;
      border-radius: 0;
      height: 35vh;
      margin-bottom: 30px;
      background-color: transparent;
      img {
        border-radius: 0;
      }
    }
  }
  #premium-bees-section {
    padding: 80px 0;
    background: #ffffff !important;
    .title-above {
      display: none;
    }
    h2 {
      font-weight: 600;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 45px;
    }
  }
  #thermal-inspection-section {
    padding: 80px 0;
    background: #f8f9fa;
  }
  #reviews-section {
    background-color: #161e2f !important;
    padding: 80px 0;
    .section-heading {
      font-weight: 600;
      color: #ffffff;
    }
    hr {
      border: 1px solid #ffa500;
      width: 50%;
    }
    .text-wrapper {
      margin: 30px 0;
    }
    .text-carousel-wrapper {
      color: #ffffff;
      .left-arrow,
      .right-arrow {
        color: #ffa500;
      }
    }
    .button-wrapper {
      margin-top: 60px;
    }
  }
  #contact-us-section {
    padding-bottom: 0;
    color: #000000;
    background-color: #f8f9fa !important;
  }

  #contact-form-section {
    padding-top: 0;
    color: #000000;
    background-color: #f8f9fa !important;
    h2 {
      color: #000000;
      font-weight: 500;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .hero-enclosure {
      .hero-title-container {
        .hero-subtitle {
          font-size: 75px;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const HomePage = () => {
  const history = useHistory();

  const textCarouselItems = [
    {
      title: "Nav Athwal",
      text: (
        <div className="text-wrapper">
          <p>
            "Great experience. Transparent throughout process. Use of technology
            to find ideal drop locations was a nice touch. Great customer
            service and hustle. Would definitely use again."
          </p>
        </div>
      ),
      image: "",
      imageAlt: "",
      href: "",
    },
    {
      title: "Rick Dykxhoorn",
      text: (
        <div className="text-wrapper">
          <p>
            "It was a positive experience. I was provided with good bee hives
            and pleased with the pollination results. The bees were brought in
            and removed with great care and respect to the property."
          </p>
        </div>
      ),
      image: "",
      imageAlt: "",
      href: "",
    },
    {
      title: "Darren Ventura",
      text: (
        <div className="text-wrapper">
          <p>"Very good experience with pollination service."</p>
        </div>
      ),
      image: "",
      imageAlt: "",
      href: "",
    },
    {
      title: "Barroso Farms",
      text: (
        <div className="text-wrapper">
          <p>"Excellent, would highly recommend."</p>
        </div>
      ),
      image: "",
      imageAlt: "",
      href: "",
    },
  ];

  return (
    <HomePageStyled>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>Exchange Bees – Almond Pollination Service</title>
        <meta name="description" content="" />
        <meta name="keywords" content=""></meta>
      </Helmet>
      <NavBar />
      <Hero jumboClass="hero-enclosure">
        <div className="hero-title-container">
          <Row>
            <Col xs={2} sm={2} md={1} className="hrLeftWrapper">
              <hr />
            </Col>
            <Col xs={10} sm={10} md={11} className="title-wrapper">
              <h2 id="hero-title">Almond Pollination Service</h2>
            </Col>
          </Row>

          <h3 className="hero-subtitle" id="">
            <span id="start">Start Using</span>
            <br />
            <span id="premium">Data-Driven Pollination</span>
          </h3>
          <p id="hero-paragraph">
            Increasing Crop Yields for Almond Growers
            <br />
            with Research, Technology, and Quality.
          </p>
        </div>
        <PrimaryButton href="#quote">Let's Grow Together</PrimaryButton>
      </Hero>
      <DidYouKnow />
      <CompanyOffers />
      <WhatDistinguishesUs></WhatDistinguishesUs>
      <BeehiveInventory></BeehiveInventory>
      <StandardSection
        idName="reviews-section"
        heading="What Our Customers Are Saying"
      >
        <hr />
        <TextCarousel carouselItems={textCarouselItems}></TextCarousel>
        <div className="button-wrapper text-center">
          <SecondaryButton href="#quote">Request a quote</SecondaryButton>
        </div>
      </StandardSection>
      <ContactUs />
      <ContactForm heading="" subheading=""></ContactForm>
      <Footer />
    </HomePageStyled>
  );
};
