import React from "react";
import styled from "styled-components";
import { Form, Col } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { SecondaryButton } from "../Buttons";
import { http } from "../../lib/http";

const ContactFormStyled = styled.div`
  color: #000;
  font-weight: bold;
  #contact-form-section {
    padding: 80px 0;
    background-size: cover;
    h2 {
      font-weight: 600;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 20px;
      margin-bottom: 45px;
    }
    .secondary-button {
      margin-top: 40px;
      align-items: center;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ContactForm = (props) => {
  // var date = new Date();

  const [form, setForm] = React.useState({});

  const submit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const response = await http.post("/api/v1/contactmessages", {
      ...form,
    });
    console.log("response", response);
    setForm({});
  };

  return (
    <ContactFormStyled>
      <StandardSection
        idName="contact-form-section"
        heading={props.heading}
        subheading={props.subheading}
      >
        <Form className="" id="quote" onSubmit={submit}>
          <Form.Row>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridEmail">
                <Form.Label>First and Last Name</Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Enter full name"
                  value={form.name || ""}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  value={form.email || ""}
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={4}>
              <Form.Group controlId="formGridPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="tel"
                  pattern="^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$"
                  required
                  placeholder="Phone"
                  value={form.phone || ""}
                  onChange={(e) => setForm({ ...form, phone: e.target.value })}
                />
              </Form.Group>
            </Col>
          </Form.Row>

          <Form.Row>
            <Col>
              <Form.Group controlId="formGridAdditionalInfo">
                <Form.Label>Additional Information</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="If you have any additional information or questions please add them here"
                  value={form.additional_info || ""}
                  onChange={(e) =>
                    setForm({ ...form, additional_info: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <div className="text-right">
            <SecondaryButton type="submit">Submit</SecondaryButton>
          </div>
        </Form>
      </StandardSection>
    </ContactFormStyled>
  );
};
