import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PrimaryButtonStyled = styled.div`
  .primary-button {
    font-size: 0.8rem;
    color: #ffffff;
    font-weight: bold;
    background-color: #ffa500;
    border-radius: 0px;
    border: 3px solid #ffa500;
    text-transform: uppercase;
  }
  .primary-button:hover {
    color: #ffa500;
    background-color: #ffffff;
    border: 3px solid #ffa500;
  }
`;

const SecondaryButtonStyled = styled.div`
  .secondary-button {
    font-size: 0.8rem;
    color: #ffa500;
    font-weight: bold;
    /* background-color: #ffffff; */
    background: transparent;
    border-radius: 0px;
    border: 2px solid #ffa500;
    text-transform: uppercase;
  }
  .secondary-button:hover {
    color: #ffffff;
    background-color: #ffa500;
  }
`;

const FontAwesomeButtonStyled = styled.div`
  margin: auto;
  .fa-button {
    background: transparent;
    border: none;
    padding: 0px;
  }
  #icon {
    color: #fff;
    font-size: 30px;
    line-height: 50px;
    display: block;
    width: 35px;
    height: 35px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
    border-radius: 100%;
    outline: 0;
    background-color: grey;
  }
`;

export const PrimaryButton = (props) => {
  return (
    <PrimaryButtonStyled className={props.className}>
      <Button className="primary-button" id="" {...props} />
    </PrimaryButtonStyled>
  );
};

export const SecondaryButton = (props) => {
  return (
    <SecondaryButtonStyled className={props.className}>
      <Button className="secondary-button" id="" {...props} />
    </SecondaryButtonStyled>
  );
};

export const FontAwesomeButton = (props) => {
  return (
    <FontAwesomeButtonStyled className={props.className} id="icon-div">
      <Button className="fa-button" {...props}>
        <FontAwesomeIcon id="icon" icon={props.icontype} border />
      </Button>
    </FontAwesomeButtonStyled>
  );
};
