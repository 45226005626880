import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";

import { HomePage } from "./pages/HomePage";
import { AboutUsPage } from "./pages/AboutUsPage";
import { PrivacyPage } from "./pages/PrivacyPage";
import { TermsPage } from "./pages/TermsPage";
import { PollinationPage } from "./pages/PollinationPage";
// import { HoneyPage } from "./pages/HoneyPage";
// import { BeesPage } from "./pages/BeesPage";
// import { IndoorStoragePage } from "./pages/IndoorStoragePage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fab, fas);

function App() {
  return (
    <div className="App">
      <Helmet></Helmet>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/privacy-policy" component={PrivacyPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/pollination" component={PollinationPage} />
          {/* <Route exact path="/honey" component={HoneyPage} />
          <Route exact path="/bees" component={BeesPage} />
          <Route exact path="/indoor-storage" component={IndoorStoragePage} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
