import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { IconBulletPoints } from "../IconBulletPoints";

const ContactUsStyled = styled.div`
  #contact-us-section {
    color: #ffffff;
    background-color: #161e2f !important;
    /* padding-top: 0px; */

    .contact-us-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #contact-us-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .contact-us-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #contact-us-paragraph {
        font-weight: 400;
      }
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const ContactUs = () => {
  const bullets = [
    {
      h4: "Our Office",
      h4Id: "",
      number: "",
      icontype: "map-marker-alt",
      numberId: "",
      p: (
        <span>
          100 Washington St. Ste 200
          <br />
          Reno, NV 89503
        </span>
      ),
      xs1: "3",
      sm1: "3",
      md1: "2",
      xs2: "9",
      sm2: "9",
      md2: "10",
    },
    {
      h4: "E-mail and Web",
      h4Id: "",
      number: "",
      icontype: "envelope",
      numberId: "",
      p: (
        <span>
          info@exchangebees.com
          <br />
          exchangebees.com
        </span>
      ),
      xs1: "3",
      sm1: "3",
      md1: "2",
      xs2: "9",
      sm2: "9",
      md2: "10",
    },
    {
      h4: "Phone Number",
      h4Id: "",
      number: "",
      icontype: "phone",
      numberId: "",
      p: <span>(916) 708-3488</span>,
      xs1: "3",
      sm1: "3",
      md1: "2",
      xs2: "9",
      sm2: "9",
      md2: "10",
    },
  ];

  return (
    <ContactUsStyled>
      <StandardSection idName="contact-us-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="contact-us-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="contact-us-title">Questions?</h2>
                </Col>
              </Row>

              <h3 className="contact-us-subtitle" id="">
                Contact Us Now
              </h3>
              <p id="contact-us-paragraph">
              California Almond Pollination Service
              </p>
              <p id="contact-us-paragraph"></p>
            </div>
          </Col>
          <Col>
            <IconBulletPoints iconCards={bullets}></IconBulletPoints>
          </Col>
        </Row>
      </StandardSection>
    </ContactUsStyled>
  );
};
