import React from "react";
import styled from "styled-components";
import { Row, Col, Card } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { SecondaryButton } from "../Buttons";

const BeehiveInventoryStyled = styled.div`
  #beehive-inventory-section {
    .beehive-inventory-title-container {
      padding-left: 0;
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #beehive-inventory-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .beehive-inventory-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #beehive-inventory-paragraph {
        font-weight: 400;
      }
    }
    .bullet-row {
      .card {
        padding: 28px 0px;
        border-radius: 30px;
        margin: 20px 0;
      }
    }

    .icon-rows {
      margin-top: 10px;
      margin-bottom: 10px;
      justify-content: center;
      .icon-numbers {
        font-size: 3px;
        font-weight: 700;
        color: #ffffff;
        background-color: #ffa500;
        border-radius: 50px;
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        margin-top: 5px;
      }
      .icon-text {
        margin-left: -3px;
        margin-right: 10px;
        h4 {
          font-size: 20px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 10px;
          color: #000000;
        }

        p,
        span {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 6px;
        }
        .secondary-button {
          margin-top: 16px;
        }
      }
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const BeehiveInventory = () => {
  const bullets = [
    {
      number: "",
      numberId: "",
      h4: "Standard Inventory",
      h4Id: "",
      p: "$50-$450 per acre",
      p2: "8-frames average",
      buttonText: "Check Availability",
    },
    {
      number: "",
      numberId: "",
      h4: "Premium Inventory",
      h4Id: "",
      p: "$50-$450 per acre",
      p2: "10-frames average",
      buttonText: "Check Availability",
    },
  ];

  return (
    <BeehiveInventoryStyled>
      <StandardSection idName="beehive-inventory-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="beehive-inventory-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="beehive-inventory-title">Bee Inventory</h2>
                </Col>
              </Row>

              <h3 className="beehive-inventory-subtitle" id="">
              Flexible Prices for Honey Bees
              </h3>
            </div>
          </Col>
        </Row>
        <Row className="bullet-row">
          {bullets.map(
            ({ number, h4, p, p2, buttonText, numberId, h4Id }, i) => (
              <Col xs={12} sm={12} md={6} key={i}>
                <Card>
                  <Row className="icon-rows">
                    <Col xs="2" sm="2" md="1">
                      <div className="icon-numbers" id={numberId}></div>
                    </Col>
                    <Col xs="10" sm="10" md="6">
                      <div className="icon-text">
                        <h4 id={h4Id}>{h4}</h4>
                        <p>{p}</p>
                        <p>{p2}</p>
                        <SecondaryButton href="#quote">
                          {buttonText}
                        </SecondaryButton>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )
          )}
        </Row>
      </StandardSection>
    </BeehiveInventoryStyled>
  );
};
