import React from "react";
import styled from "styled-components";
import { Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconGridCardsStyled = styled.div`
  .icon-rows:first-of-type {
    /* margin-top: 20px; */
  }
  #icon-card-col {
    margin: 0;
    padding: 0;
  }
  #icon-cards-0 {
    border-right: 1px solid rgb(255, 255, 255, 0.25);
    border-bottom: 1px solid rgb(255, 255, 255, 0.25);
  }
  #icon-cards-1 {
    border-left: 1px solid rgb(255, 255, 255, 0.25);
    border-bottom: 1px solid rgb(255, 255, 255, 0.25);
  }
  #icon-cards-2 {
    border-right: 1px solid rgb(255, 255, 255, 0.25);
    border-top: 1px solid rgb(255, 255, 255, 0.25);
  }
  #icon-cards-3 {
    border-left: 1px solid rgb(255, 255, 255, 0.25);
    border-top: 1px solid rgb(255, 255, 255, 0.25);
  }
  .icon-cards {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0;
    padding: 20px;
    #icon {
      font-size: 40px;
      color: #ffa500;
    }
    .card-body {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      .card-title {
        font-weight: 800;
        text-transform: uppercase;
        margin-bottom: 4px;
        text-align: center;
      }
      .card-text {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
      }
    }
  }

  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const IconGridCards = (props) => {
  return (
    <IconGridCardsStyled>
      <Col xs={12} sm={12} md={12}>
        <Row className="icon-rows">
          {props.iconCards.map(({ h4, p, iconId, h4Id, icontype }, i) => (
            <Col xs="6" sm="6" md="6" key={i} id="icon-card-col">
              <Card className="icon-cards" id={`icon-cards-${i}`}>
                <div className="fa-icons" id={iconId}>
                  <FontAwesomeIcon id="icon" icon={icontype} />
                </div>
                <Card.Body>
                  <Card.Title id={h4Id}>{h4}</Card.Title>
                  <Card.Text>{p}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    </IconGridCardsStyled>
  );
};
