import React from "react";
import styled from "styled-components";
import { Nav, Navbar, NavDropdown, Image } from "react-bootstrap";
import logo from "../assets/imgs/exchange-bees-logo.png";
import { SecondaryButton, FontAwesomeButton } from "./Buttons";

const NavBarStyled = styled.div`
  padding: 5px;
  .navigation {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 2px -2px;
  }
  .logo-image {
    height: 40px;
    width: auto;
  }
  #phone-button {
    margin-right: 10px;
    display: none;
  }
  #phone-button-mobile {
    display: inherit;
    margin-right: 5px;
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    #phone-button {
      display: inherit;
    }
    #phone-button-mobile {
      display: none;
    }
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const NavBar = () => {
  return (
    <NavBarStyled>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="#fff"
        variant="light"
        fixed="top"
        className="navigation"
      >
        <Navbar.Brand href="/">
          <Image
            src={logo}
            alt=""
            width=""
            height="40"
            className="d-inline-block align-top logo-image"
          />
        </Navbar.Brand>
        <FontAwesomeButton
          id="phone-button-mobile"
          icontype={["fas", "mobile-alt"]}
          href="tel:1-916-708-3488"
        />
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about-us">About Us</Nav.Link>
            <NavDropdown title="Beekeepers" id="basic-nav-dropdown">
              <NavDropdown.Item href="/pollination">
                Pollination
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="/honey">Honey</NavDropdown.Item>
              <NavDropdown.Item href="/bees">Bees</NavDropdown.Item>
              <NavDropdown.Item href="/indoor-storage">
                Indoor Storage
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
          <Nav>
            <FontAwesomeButton
              id="phone-button"
              icontype={["fas", "mobile-alt"]}
              href="tel:1-916-708-3488"
            />
            <SecondaryButton href="#quote">Get a free quote</SecondaryButton>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </NavBarStyled>
  );
};
