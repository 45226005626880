import React from "react";
import styled from "styled-components";
import { Row, Col, Media } from "react-bootstrap";
import { StandardSection } from "../StandardSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hiveImg from "../../assets/imgs/hive.png";
// import thermalhiveImg from "../assets/imgs/thermal-bee-hive.jpeg";

const CompanyOffersStyled = styled.div`
  #company-offers-section {
    color: #000000;
    background-color: #ffffff !important;

    .company-offers-title-container {
      .title-wrapper {
        align-items: center;
        display: flex;
        padding-left: 0;
        #company-offers-title {
          color: #ffa500;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid #ffa500;
        width: 100%;
      }
      .company-offers-subtitle {
        font-weight: 600;
        margin: 5px 0 25px 0;
      }
      #company-offers-paragraph {
        font-weight: 400;
      }
      img {
        display: none;
      }
    }
    .media-card {
      background-color: #ffa500;
      margin: 20px 0;
      padding: 20px 20px 8px 20px;
      h4 {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 4px;
      }
      p {
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
      }
      #icon {
        font-size: 40px;
        color: #ffffff;
        margin: 0 20px 0 5px;
      }
    }
    #experience-media {
      border-radius: 30px 30px 0px 0px;
    }
    #technology-media {
      border-radius: 0px 0px 30px 30px;
    }
  }
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    #company-offers-section {
      .company-offers-title-container {
        img {
          display: unset;
          margin: 18px 28px 0 0;
          width: 35%;
        }
      }
    }
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const CompanyOffers = () => {
  const offerCards = [
    {
      h4: "Coverage & Experience",
      h4Id: "",
      mediaId: "experience-media",
      icontype: "shield-alt",
      p: (
        <span>
          With beekeeping coverage in the entire state of California, we’re able
          to overcome all obstacles.
        </span>
      ),
    },
    {
      h4: "Research & Development",
      h4Id: "",
      mediaId: "",
      icontype: "archive",
      p: (
        <span>
          Newly developed methods combined with technology allow for maximum bee
          pollination.
        </span>
      ),
    },
    {
      h4: "Infrared Image Analysis",
      h4Id: "",
      mediaId: "",
      icontype: "industry",
      p: (
        <span>
          Non-Invasive inspection method showing accurate beehive strengths to
          ensure your quality.
        </span>
      ),
    },
    {
      h4: "Strategic Placement",
      h4Id: "",
      mediaId: "technology-media",
      icontype: "leaf",
      p: (
        <span>
          Optimal placement pattern for the distribution of beehives that will
          improve your pollination output.
        </span>
      ),
    },
  ];

  return (
    <CompanyOffersStyled>
      <StandardSection idName="company-offers-section">
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="company-offers-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="company-offers-title">Bee Services</h2>
                </Col>
              </Row>

              <h3 className="company-offers-subtitle" id="">
                Our Company Offers
              </h3>
              <p id="company-offers-paragraph">
              Thorough inspections to show the inventory strengths of the beehives brought to you. You should not pay for colonies that do not meet your frame requirements.
              </p>
              <div className="text-center">
                <img src={hiveImg} alt="" />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={6}>
            {offerCards.map(
              ({ h4, h4Id, p, mediaId, imgSrc, imgAlt, icontype }, i) => (
                <Media className="media-card" id={mediaId} key={i}>
                  <FontAwesomeIcon id="icon" icon={icontype} />
                  <Media.Body>
                    <h4>{h4}</h4>
                    <p>{p}</p>
                  </Media.Body>
                </Media>
              )
            )}
          </Col>
        </Row>
      </StandardSection>
    </CompanyOffersStyled>
  );
};
