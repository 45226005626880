import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { StandardSection } from "../StandardSection";

const AboutUsStyled = styled.div`
  .about-us-title-container {
    .title-wrapper {
      align-items: center;
      display: flex;
      padding-left: 0;
      #about-us-title {
        color: #ffa500;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
        text-transform: uppercase;
      }
    }

    hr {
      border-top: 2px solid #ffa500;
      width: 100%;
    }
    .about-us-subtitle {
      font-weight: 600;
      margin: 5px 0 25px 0;
    }
    #about-us-paragraph {
      font-weight: 400;
    }
  }
  .img-col {
    display: flex;
    img {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const AboutUs = () => {
  let d1 = Date.now();
  let d2 = new Date(1995, 1, 1).getTime();

  let date1 = new Date(d1);
  let date2 = new Date(d2);
  let yearsDiff = 1 + (date1.getFullYear() - date2.getFullYear());

  let yearsInBusiness = yearsDiff.toString();

  return (
    <AboutUsStyled>
      <StandardSection>
        <Row>
          <Col xs={12} sm={12} md={6}>
            <div className="about-us-title-container">
              <Row>
                <Col xs={2} sm={2} md={2} className="hrLeftWrapper">
                  <hr />
                </Col>
                <Col xs={10} sm={10} md={10} className="title-wrapper">
                  <h2 id="about-us-title">Our History</h2>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <div className="about-us-title-container">
              <h3 className="about-us-subtitle" id="">
                Reliable. Reputable. Transparent.
              </h3>
              <p id="about-us-paragraph">
              Our family started delivering beehives to California almond pollination in 1995. We grew our business until the 2008 housing market crash and were forced to sell our operation which was renamed Flowing Gold Apiaries, Inc. We got back into the industry in 2012 and started building beehives for almonds again.
              </p>
              <p id="about-us-paragraph">
              During the 2016 almond pollination, we were scammed by a bee-broker. This bee-broker was paid in full by the farmer but decided that the bees weren’t to his standards. Our bees were of great quality and there were no concerns until after we removed them from the orchard. We had a standard hand-shake agreement.
              </p>
              <p id="about-us-paragraph">
              We were awaiting our first major payday for our 440 colonies and then were left without money for our business and our family. We went into debt paying for the freight & feed to deliver the bees and only retrieved a fraction after legal action.
              </p>
              <p id="about-us-paragraph">
              5 years ago due to the financial stress our family endured, Exchange Bees was founded to provide california almond farmers and commercial beekeepers a pollination corporation with security, transparency, and technology. 
              </p>
              <p id="about-us-paragraph">
              We’re thankful today to be working with our farmers throughout the california central valley and serving 60,000+ acres to be placed for pollination of various crops.
              </p>
            </div>
          </Col>
        </Row>
      </StandardSection>
    </AboutUsStyled>
  );
};
