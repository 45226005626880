import React from "react";
import styled from "styled-components";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";

const HeroStyled = styled.div`
  color: white;
  font-weight: 300;
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

export const Hero = (props) => {
  return (
    <HeroStyled>
      <Jumbotron fluid className={props.jumboClass}>
        <Container>
          <Row>
            <Col id={props.upperRowId}>
              <h1>{props.title}</h1>
              <h1>{props.titleTwo}</h1>
              <h3>{props.subtitle}</h3>
              {props.children}
            </Col>
          </Row>
          <Row id={props.lowerRowId}>
            <Col md={{ span: 2, offset: 10 }}>
              <div className="ptag-enclosure">
                <p>{props.lowerText1}</p>
                <p>{props.lowerText2}</p>
                <p>{props.lowerText3}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
    </HeroStyled>
  );
};
