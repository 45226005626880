import React from "react";
import styled from "styled-components";
import { Bar } from "react-chartjs-2";

const FieldForceBarChartStyled = styled.div`
  @media (min-width: 576px) {
  }

  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
`;

const state = {
  labels: ["4", "5", "6", "7", "8", "9", "10", "11", "12"],
  datasets: [
    {
      color: "#000000",
      label: "%",
      backgroundColor: [
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
        "#ffa500",
      ],

      borderWidth: 2,
      data: [10, 12.5, 25, 30, 40, 42.5, 45, 47.5, 50],
    },
  ],
};

export const FieldForceBarChart = (props) => {
  return (
    <FieldForceBarChartStyled>
      <div id={props.idName}>
        <Bar
          data={state}
          width={100}
          height={70}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            title: {
              display: true,
              text: "Effective Field Force vs. Frames",
              fontSize: 20,
              fontColor: "#000000",
            },
            legend: {
              display: false,
              position: "right",
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: "#000000",
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Effective Field Force",
                    fontColor: "#000000",
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: "#000000",
                  },
                  scaleLabel: {
                    display: true,
                    labelString: "Frames",
                    fontColor: "#000000",
                  },
                },
              ],
            },
          }}
        />
      </div>
    </FieldForceBarChartStyled>
  );
};
